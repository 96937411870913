<template>
  <div class="ui preloader" :style="{ height }">
    <img src="../assets/img/preloader.svg" alt="loaded" />
    <label class="desc">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '200px',
    },
    label: {
      type: String,
      default: "Loading"
    }
  },
}
</script>

<style lang="scss">
.preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .desc {
    color: #333;
    padding-top: 10px;
  }
}
</style>